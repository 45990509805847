import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AccountingService } from '../../Services/AccountingService';
import { DashboardService } from '../../Services/DashboardService';

@Component({
  selector: 'app-accounting-report',
  templateUrl: './accounting-report.component.html',
  styleUrls: ['./accounting-report.component.css']
})
export class AccountingReportComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  filteredValues;
  loading: boolean = false;
  sYear: number = new Date().getFullYear();
  sMonth: number = new Date().getMonth() + 1;
  eYear: number = new Date().getFullYear();
  eMonth: number = new Date().getMonth() + 1;
  Year: number = new Date().getFullYear();
  Years: number[] = [this.Year];
  Month: number = new Date().getMonth();
  Months = [
    { "key": 1, "value": "January" },
    { "key": 2, "value": "February" },
    { "key": 3, "value": "March" },
    { "key": 4, "value": "April" },
    { "key": 5, "value": "May" },
    { "key": 6, "value": "June" },
    { "key": 7, "value": "July" },
    { "key": 8, "value": "August" },
    { "key": 9, "value": "September" },
    { "key": 10, "value": "October" },
    { "key": 11, "value": "November" },
    { "key": 12, "value": "December" }
  ];
  cols;
  // _selectedColumns: any[];
 selectedColumns: any[];
  ARColumns;
  colTotal;
  @ViewChild('dt') table: Table;
  subTitle = "In Home Date";
  constructor(private router: Router, private securityService: SecurityService,
    public dashboardService: DashboardService,
    private accountingService: AccountingService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']); };
    this.dashboardService.getSalesPersonFilter();
    this.Years[0] = this.Year;
    for (let i = this.Year - 1; i > 2012; i--) { this.Years.push(i); }
    var d = new Date().getMonth();
    if (d >= 11) { this.Years.unshift(this.Years[0] + 1); }
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear);
    this.cols = [
      { field: 'OrderID', header: 'Item #' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'ShipDate', header: 'Ship Date' },
      { field: 'Customer', header: 'Customer' },
      { field: 'SalesPerson', header: 'Salesperson' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Revenue', header: 'Revenue' },
      { field: 'Postage', header: 'Postage' },
      { field: 'Printer', header: 'Printer' },
      { field: 'Shipper', header: 'Shipper' },
      { field: 'Mailer', header: 'Mailer' },
      { field: 'List', header: 'List' },
      //{ field: 'DesignerCost', header: 'Rise' },
      { field: 'CreditCardFee', header: 'Credit Card Fee' },
      { field: 'Profit', header: 'Profit' },
      { field: 'GrossMargin', header: 'Gross Margin' }
    ];
    this.colTotal = [
      { field: 'OrderID', Total: '' },
      { field: 'InHomeDate', Total: '' },
      { field: 'Customer', Total: '' },
      { field: 'SalesPerson', Total: '' },
      { field: 'Quantity', Total: '0' },
      { field: 'Revenue', Total: '0' },
      { field: 'Postage', Total: '0' },
      { field: 'Printer', Total: '0' },
      { field: 'Shipper', Total: '0' },
      { field: 'Mailer', Total: '0' },
      { field: 'List', Total: '0' },
   //   { field: 'DesignerCost', header: 'Rise' },
      { field: 'CreditCardFee', header: 'Credit Card Fee' },
      { field: 'Profit', Total: '0' }
    ];
     this.selectedColumns = this.cols;
   // this.selectedColumns = this.cols;
    //this.ARColumns = JSON.parse(window.localStorage.getItem('ARColumns'));
    //if (this.ARColumns === null) { window.localStorage.setItem('ARColumns', JSON.stringify(this._selectedColumns)); }
    //else { this._selectedColumns = this.ARColumns }
    //  }

    //@Input() get selectedColumns(): any[] {
    //  var x = this._selectedColumns;
    //  window.localStorage.setItem('ARColumns', JSON.stringify(x));
    //  return x;
  }

  //set selectedColumns(val: any[]) {
  //  //restore original order
  //  this._selectedColumns = this.cols.filter(col => val.includes(col));
  //  window.localStorage.setItem('ARColumns', JSON.stringify(this.selectedColumns));
  //}


  getData(sm, sy, em, ey) {
    this.loading = true;
    var s;
    var e;
    if (sm.length < 2) sm = '0' + sm;
    s = [sy, sm, '01'].join('-');
    if (em.length < 2) em = '0' + em;
    e = [ey, em, '01'].join('-');
    if (this.subTitle !== "Received Date") {
      this.accountingService.getAcctRptInHome(s, e).subscribe(data => {
        this.data = data;
        this.getSum();
        this.loading = false;
      });
    }
    //if (this.subTitle !== "Shipped Date") {
    //  this.accountingService.getAcctRptShippedDate(s, e).subscribe(data => {
    //    this.data = data;
    //    this.getSum();
    //    this.loading = false;
    //  });
    //}
    else {
      this.accountingService.getAcctRptRec(s, e).subscribe(data => {
        this.data = data;
        this.getSum();
        this.loading = false;
      });
    }
  }

  getSum() {
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.data.length; x++) {
          fieldSum += this.data[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.filteredValues.length; x++) {
          fieldSum += this.filteredValues[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }

  getFilterChange() {
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear);
  }

  IHDate() {
    this.subTitle = "In Home Date";
    var x = this.cols.filter(x => x.header == 'Received Date')[0];
    // if (x === undefined) x = this.selectedColumns.filter(x => x.header == 'Shipped Date')[0];
    x.header = 'In Home Date';
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear);
  }

  RecDate() {
    this.subTitle = "Received Date";
    var x = this.cols.filter(x => x.header == 'In Home Date')[0];
    //  if (x === undefined) x = this.selectedColumns.filter(x => x.header == 'Shipped Date')[0];
    x.header = 'Received Date';
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear);
  }

  //ShipDate() {
  //  this.subTitle = "Shipped Date";
  //  var x = this.selectedColumns.filter(x => x.header == 'In Home Date')[0];
  //  if (x === undefined) x = this.selectedColumns.filter(x => x.header == 'Received Date')[0];
  //  x.header = 'Shipped Date';
  //  this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear);
  //}

  // saveReorderedColumns(event: any) { window.localStorage.setItem('ARColumns', JSON.stringify(this.selectedColumns)); }

  getColTotal(fld) {
    for (let i = 0; i < this.colTotal.length; i++) {
      if (fld === this.colTotal[i].field) return this.colTotal[i].Total;
    }
    return '';
  }
}

